<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">学生列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加学生</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border v-loading="allloading">
			<el-table-column type="index" align="center" width="50" />
			<el-table-column align="center" prop="studentName" label="姓名" />
			<el-table-column align="center" prop="studentCode" label="学号" />
			<el-table-column align="center" prop="gradeDto.gradeName" label="年级" />
			<el-table-column align="center" prop="classroomName" label="班级" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<!-- <el-button size="small" type="primary" @click="edits(scope.row)">修改
					</el-button> -->
					<el-button size="small" type="danger" @click="deletes(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>



	<el-dialog v-model="isadd" title="添加学生" width="30%">
		<div class="row">
			<div class="row-title">学生名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.studentName" placeholder="请输入要添加的学生名称" clearable type="text"></el-input>
		</div>
		
		<div class="row">
			<div class="row-title">学号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.studentCode" placeholder="请输入描述" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">性别<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.gender" placeholder="请选择性别" style="width: 100%;">
				<el-option label="女" value="0" />
				<el-option label="男" value="1" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.gradeId" @change="gradechange" placeholder="请选择年级" style="width: 100%;">
				<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">班级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.classroomId" placeholder="请选择班级" style="width: 100%;">
				<el-option v-for="item in classList" :label="item.classroomName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学年<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.admissionYear" placeholder="请选择出版社" style="width: 100%;">
				<el-option v-for="item in years" :label="item" :value="item" :key="item" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">所属学校<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.tenantId" placeholder="请选择所属学校" style="width: 100%;">
				<el-option v-for="item in tenantsList" :label="item.tenantName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="isedit" title="修改学生" width="20%">
		<div class="row">
			<div class="row-title">学生名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.subjectName" placeholder="请输入要添加的学生名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">描述<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.subjectDesc" placeholder="请输入描述" clearable type="text"></el-input>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isedit = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		studentlist,
		studentadd,
		studentedit,
		studentdelete,
		gradeslist,
		classroomslist,
		tenantslist
	} from "../../util/api"
	export default {
		data() {
			return {
				allloading: false,
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				isadd: false,
				isedit: false,
				addjson: {
					studentName: null, //姓名
					studentCode: null, //学号
					classroomId: null, //班级
					gradeId: null, //年级
					gender: null, //性别
					admissionYear: null, //学年
					tenantId: null //学校
				},
				editjson: {
					id: null,
					subjectName: null,
					subjectDesc: null
				},
				loading: false,
				gradeList: null, //年级列表
				classList: null, //班级列表
				tenantsList: null, //租户列表
				years: []
			}
		},
		created() {
			this.getlist()
			this.getgradeslist()
			this.gettenantslist()
			let newdate = new Date()
			let yearlist = []
			for (let i = 0; i < 5; i++){
				yearlist.push(newdate.getFullYear() - i)
			}
			this.years = yearlist
		},
		methods: {
			gettenantslist() {
				tenantslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.tenantsList = res.data.list
				})
			},
			gradechange() {
				this.addjson.classroomId = null
				if (this.addjson.gradeId != null) {
					this.getclasslist(this.addjson.gradeId)
				} else {
					this.classList = null
				}
			},
			getclasslist(id) { //根据年级ID查询班级列表
				classroomslist({
					pageNum: 1,
					pageSize: 999999,
					gradeId: id
				}).then((res) => {
					this.classList = res.data.list
				})
			},
			getgradeslist() {
				gradeslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.gradeList = res.data.list
				})
			},
			submitedit() {
				if (!this.editjson.subjectName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.editjson.subjectDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else {
					this.loading = true
					subjectsedit(this.editjson).then((res) => {
						this.loading = false
						this.isedit = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.getlist()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			edits(row) {
				this.editjson = {
					id: row.id,
					subjectName: row.subjectName,
					subjectDesc: row.subjectDesc
				}
				this.isedit = true
			},
			deletes(row) {
				this.$confirm('是否删除学生“' + row.studentName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					studentdelete(row.id).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			submitadd() {
				if (!this.addjson.studentName) {
					this.$message({
						type: 'warning',
						message: '请输入姓名',
					})
				} else if (!this.addjson.studentCode) {
					this.$message({
						type: 'warning',
						message: '请输入学号',
					})
				}else if(!this.addjson.gender){
					this.$message({
						type: 'warning',
						message: '请选择性别',
					})
				} else if (!this.addjson.gradeId) {
					this.$message({
						type: 'warning',
						message: '请选择年级',
					})
				} else if (!this.addjson.classroomId) {
					this.$message({
						type: 'warning',
						message: '请选择班级',
					})
				} else if (!this.addjson.admissionYear) {
					this.$message({
						type: 'warning',
						message: '请选择学年',
					})
				} else if (!this.addjson.tenantId) {
					this.$message({
						type: 'warning',
						message: '请选择所属学校',
					})
				} else {
					this.loading = true
					studentadd(this.addjson).then((res) => {
						console.log(res)
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '添加成功'
							})
							this.addjson = {
								studentName: null, //姓名
								studentCode: null, //学号
								classroomId: null, //班级
								gradeId: null, //年级
								gender: null, //性别
								admissionYear: null, //学年
								tenantId: null //学校
							}
							this.loading = false
							this.isadd = false
							this.getlist()
						} else {
							this.loading = false
						}
					}).catch(() => {
						this.loading = false
					})
				}
			},
			getlist() { //获取列表
			this.allloading = true
				studentlist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res) => {
					this.list = res.data.list
					this.pages = res.data.pages
					this.allloading = false
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
